<template>
  <section class="content-page" ref="home" style="height: 1000px; overflow: auto" v-infinite-scroll="InfinitePostData"
    infinite-scroll-disabled="postInfiniteDisabled" infinite-scroll-delay="500" infinite-scroll-immediate="true"
    infinite-scroll-distance="100">
    <b-row>
      <b-col sm="12" class="p-0">
        <b-row class="m-0 p-0">
          <b-col md="3">
            <!-- Upcoming Event -->
            <!-- <iq-card class="upcomming_event">
              <template v-slot:headerTitle>
                <h4 class="card-title">Upcoming Event</h4>
              </template>
              <template v-slot:body>
                <ul class="date_list m-0 p-0">
                  <li
                    v-for="(item, index) in comingEvent"
                    :key="index"
                    class="mb-4"
                  >
                    <i class="ri-calendar-event-line"></i>
                    <div class="stories_data">
                      <h5>{{ item.title }}</h5>
                      <p class="mb-0">
                        {{ item.start_time }} | {{ item.location }}
                      </p>
                    </div>
                  </li>
                </ul>
                <b-link
                  @click="$router.push('/event')"
                  class="btn btn-primary d-block mt-3"
                  >See All</b-link
                >
              </template>
            </iq-card> -->

            <!-- Suggested Pages -->
            <iq-card class="m-0">
              <!-- <template v-slot:headerTitle>
                <h4 class="card-title">Suggested Channels</h4>
              </template>
              <template v-slot:body>
                <ul class="suggested-page-story m-0 p-0 list-inline">
                  <li
                    class="mb-3"
                    v-for="(item, index) in channel"
                    :key="index"
                  >
                    <router-link :to="`/organization-detail?channel_id=${item.id}`">
                      <div class="d-flex mb-3">
                        <img
                          src="@/assets/images/page-img/42.png"
                          alt="story-img"
                          class="rounded-circle img-fluid avatar-50"
                        />
                        <div class="stories-data ml-3">
                          <h5>{{ item.title }}</h5>
                          <p
                            style="color: #333"
                            v-html="item.introduction ? item.introduction : '-'"
                            class="mb-0"
                          ></p>
                        </div>
                      </div>
                      <img
                        :src="item.image"
                        class="img-fluid rounded w-100"
                        alt="Responsive image"
                      />
                    </router-link>
                    <div
                      class="mt-3"
                      @click="handlerClickAttention(item.id, index)"
                    >
                      <span
                        class="btn d-block"
                        :class="isAttentionActive(index)"
                      >
                        <i class="ri-thumb-up-line mr-2"></i>
                        Follow
                      </span>
                    </div>
                  </li>
                </ul>
              </template> -->
            </iq-card>

            <!-- Project -->
            <iq-card class="leftEvent" v-if="projectList.length">
              <template v-slot:headerTitle>
                <h4 class="card-title">Events</h4>
              </template>
              <template v-slot:body>
                <ul class="suggested-page-story m-0 p-0 list-inline">
                  <li class="mb-3" v-for="(item, index) in projectList" :key="index">
                    <a :href="item.ad_link" target="_blank">
                      <div class="d-flex align-items-center mb-3">
                        <div class="stories-data">
                          <h5>{{ item.ad_title }}</h5>
                          <p class="mb-0" style="color: #333">
                            {{ item.start_date }}
                          </p>
                        </div>
                      </div>
                      <img v-if="item.content" :src="item.content" class="img-fluid rounded" alt="Responsive image" />
                    </a>
                  </li>
                </ul>
              </template>
            </iq-card>

            <!-- JCI-img -->
            <iq-card class="leftImg">
              <template v-slot:body>
                <div style="margin: 0 auto">
                  <img src="@/assets/images/home/homeLeft.png" class="img-fluid rounded img_auto" />
                </div>
              </template>
            </iq-card>

            <ul class="ad-list p-0">
              <li v-for="(item, index) in adLeftList" :key="index">
                <a @click="clickAd(item.id)" :href="item.ad_link" target="_Blank">
                  <img :src="item.content" alt="" />
                </a>
              </li>
            </ul>

            <!-- <div>
              <ul class="m-0 p-0 d-flex bottomItem">
                <li>About</li>
                <li>User Agreement</li>
                <li>Privacy Policy</li>
                <li>Cookies Policy</li>
                <li>JCI©2020</li>
              </ul>
              <div class="language">
                <select @change="selectLang" class="form-control selection">
                  <option v-for="(v, i) in languageList" :key="i">
                    {{ v }}
                  </option>
                </select>
              </div>
            </div> -->
          </b-col>
          <b-col md="6">
            <tab-content v-if="!$route.params.id" id="pills-tabContent-2">
              <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
                <AddSocialPost :isShowAddPostModal="true" @addPost="addPost" :friendList="friendsList"
                  :noShowPermission="true"></AddSocialPost>
                <div v-for="(post, index) in homePostList" :key="index">
                  <social-post :post="post" :isShowViewAllComment="true" pageType="home" @refreshPostList="fresh"
                    @SharePostDetail="getSharePostDetStatus" @freshHomePost="freshHomePost"></social-post>
                </div>
                <div v-if="postLoading">
                  <el-skeleton animated :count="4">
                    <template slot="template">
                      <iq-card body-class="p-0">
                        <div style="padding: 14px;" class="d-flex align-items-center">
                          <el-skeleton-item variant="circle" style="width: 60px; height: 60px;" />
                          <div class="ml-3 d-flex flex-column">
                            <el-skeleton-item variant="text" style="width: 120px;" />
                            <el-skeleton-item variant="text" style="width: 80px;margin-top: 10px;" />
                          </div>
                        </div>
                        <div style="padding: 20px;" class="d-flex justify-content-between mt-5">
                          <el-skeleton-item variant="text" style="width: 60px;" />
                          <el-skeleton-item variant="text" style="width: 60px;" />
                          <el-skeleton-item variant="text" style="width: 60px;" />
                        </div>
                      </iq-card>
                    </template>
                  </el-skeleton>
                </div>
                <p v-if="noMore" class="no_more">There's no more content</p>
              </tab-content-item>
            </tab-content>

            <!-- 详情 -->
            <section v-else>
              <section ref="post_det" class="share_post_detail" v-if="isShowDetail">
                <iq-card class="detail_back">
                  <template v-slot:headerTitle>
                    <div class="d-flex align-items-center" style="cursor: pointer" @click="$router.push('/newsfeeds')">
                      <i class="las la-angle-left mr-1"></i>
                      <span>Back</span>
                    </div>
                  </template>
                </iq-card>
                <AddSocialPost :isShowAddPostModal="false" @addPost="addPost" :friendList="friendsList"
                  :noShowPermission="true"></AddSocialPost>

                <SocialPost :post="postDetailInfo" pageType="home" :isShowViewAllComment="false"
                  :friendsList="friendsList"></SocialPost>
                <!-- {{ postDetailInfo }} -->
              </section>
              <section v-else style="background:#fff;" class="d-flex justify-content-center">
                <img src="../../assets/images/group/empty1.png" alt="" style="width:250px;margin-top: 60px" />
              </section>
            </section>
          </b-col>
          <b-col md="3">
            <ul class="ad-list p-0">
              <li v-for="(item, index) in adList" :key="index">
                <a @click="clickAd(item.id)" :href="item.ad_link" target="_Blank">
                  <img :src="item.content" alt="" />
                </a>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div v-if="isShowScrollNumber" @click="postListToTop" class="toTop">
      <i class="ri-upload-line"></i>
    </div>
  </section>
</template>
<script>
  import SocialPost from '@/components/ComComponents/ComPost/SocialPost1'
  import AddSocialPost from '@/components/ComComponents/ComPost/AddSocialPost'
  import { throttle } from '@/Utils/globalFn'
  import { InfiniteScroll } from 'element-ui'
  export default {
    name: 'SocialApp',
    components: { AddSocialPost, SocialPost },

    data() {
      return {
        adList: [],
        adLeftList: [],
        postInfiniteDisabled: false,
        homePostList: [],
        friendsList: [],
        comingEvent: [],
        channel: [],
        projectList: [],
        active: false,
        // 是否分享
        isShare: false,
        postDetail: {},

        isShowHomePost: true,
        postDetailInfo: {},
        postType: 0,
        page: 0,
        totalPage: 0,

        highlightList: [],
        // languageList: ["English", "Chinese"],

        isShowDetail: true,

        // 帖子列表滚动的距离
        isShowScrollNumber: 0,
        postLoading: false,
        noMore: false
      }
    },
    directives: {
      'infinite-scroll': InfiniteScroll
    },
    mounted() {
      let postListContainer = this.$refs.home
      postListContainer.addEventListener('scroll', (e) => {
        this.isShowScrollNumber = e.target.scrollTop
      })
    },
    computed: {
      postKey() {
        return new Date().getTime()
      }
    },
    watch: {
      /**
         * 监听进入详情后：
         *  滚动条在最顶端
         *  滚动加载
         *  如果有参数就请求帖子详情
         * */
      '$route.params.id': {
        handler() {
          // 切换页面时滚动条自动滚动到顶部
          window.scrollTo(0, 0)
          document.addEventListener('scroll', this.scrollLoad)
          let id = this.$route.params.id
          if (id) {
            this.getPostDetail(id)
          }
        },

        deep: true
      }
    },
    created() {
      this.getHomeLeft()
      this.getUserFriendList()

      // 刷新页面的话就重新获取详情
      if (this.$route.params.id) {
        this.getPostDetail(this.$route.params.id)
      }

      this.getHomeAd()
      this.getHomeAdLift()

      // 初始进入页面
      this.InfinitePostData()
    },

    methods: {

      postListToTop() {
        let postListContainer = this.$refs.home
        let top = postListContainer.scrollTop
        // 实现滚动效果
        const timeTop = setInterval(() => {
          postListContainer.scrollTop = top -= 50
          if (top <= 0) {
            clearInterval(timeTop)
          }
        }, 10)
      },

      // 广告列表
      getHomeAd() {
        this.$http.getHomeAd().then((res) => {
          this.adList = res.data
        })
      },

      // 广告列表(左侧)
      getHomeAdLift() {
        this.$http.getHomeAdLift().then((res) => {
          this.adLeftList = res.data
        })
      },

      clickAd(id) {
        this.$http
          .adHitsIncr({
            id: id
          })
          .then((res) => {
            // console.log(res);
          })
      },
      selectLang(v) {
        this.$i18n.locale = v.target.value == 'English' ? 'en' : 'zh_cn'
      },
      handlerClickAttention(channel_id, index) {
        let isAttention = this.highlightList[index]
        isAttention
          ? this.channelFollow(channel_id, index, 2)
          : this.channelFollow(channel_id, index, 1)
      },
      // 关注
      channelFollow(channel_id, index, type) {
        this.$http.channelFollow({ channel_id, type }).then((res) => {
          if (res.status == 200) {
            if (type == 2) {
              this.$set(this.highlightList, index, false)
            } else {
              this.$set(this.highlightList, index, true)
            }
          }
        })
      },
      isAttentionActive(index) {
        return this.highlightList[index] ? 'attentionActive' : 'attention'
      },
      getHomeLeft() {
        this.$http.getEvent().then((res) => {
          this.comingEvent = res.data.salon
          this.channel = res.data.channel
          this.highlightList = this.channel.map(
            (v, i) => (this.highlightList[i] = false)
          )
          this.projectList = res.data.project
          // console.log("主页", res.data);
        })
      },

      InfinitePostData() {
        this.page++
        this.getPostList()
      },

      // 刷新首页帖子
      freshHomePost() {
        this.postType = 0
        this.page = 0
        this.homePostList = []
        this.InfinitePostData()
      },
      // 获取首页帖子
      getPostList() {
        this.postLoading = true
        // console.log('home1111111', this.postType)
          // type: !this.postType ? 'friend' : 'other',
        this.$http.homePost({
          page: this.page,
          size: 10,
          ad_per_page: 1,
        }).then((res) => {
          /**
             * !!!!!!!!!!!!!!!!!!!
             * 新发帖以后重新获取列表，没有返回自己刚发的这条帖子
             * !!!!!!!!!!!!!!!!!!!!
             * */
          // console.log('getPostlist', res.data)
          this.homePostList = [...this.homePostList, ...res.data.data]

          // friend加载完以后res.data.is_last &&
          // if (!this.postType && res.data.total_page==this.page) {
          //   // console.log(1)
          //   this.postType = 1
          //   this.page = 0
          //   this.InfinitePostData()
          //   return
          // }

          // other加载完以后
          if (this.postType && res.data.total_page==this.page) {
            // console.log(2)
            this.postInfiniteDisabled = true
            this.noMore = true
          }

          setTimeout(() => {
            this.postLoading = false
          }, 0);
        })
      },

      // permision--好友列表
      getUserFriendList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.$http
          .getUserFriendList({
            user_id: userInfo.user_id,
            type: 4
          })
          .then((res) => {
            // console.log('friend', res.data);
            this.friendsList = res.data
          })
      },

      addPost() {
        // console.log(arguments)
        let [post, permission] = arguments
        this.$http.createPost({
          type: 1, // 个人发帖
          permission: permission,
          ...post
        }).then((res) => {
          // console.log('home', res)
          if (res.status == 200) {
            this.postType = 0
            this.page = 0
            this.homePostList = []
            this.InfinitePostData()
          }
        })
      },

      // 评论-点赞-删除的重新获取帖子列表
      fresh(val) {
        if (val) {
          this.getPostList()
          history.go(0)
        }
      },

      getSharePostDetStatus(post) {
        this.$router.push({
          path: `/newsfeeds/${post.info_url}`
        })
        // this.isShowHomePost = false;
        // this.getPostDetail(post.info_url);
      },

      // 获取分享的帖子的详情
      // 获取帖子的信息
      async getPostDetail(id) {
        let res = await this.$http.postDetail({
          user_post_id: id || this.$route.params.id
        })
        if (res.status == 200) {
          this.isShowDetail = true
          this.$set(res.data, 'isShowCommentList', true)
          this.$set(res.data, 'firstCommentList', [])
          this.postDetailInfo = res.data
          this.getFirstCommentList()
        } else {
          this.isShowDetail = false
        }
      },

      // // 获取顶级评论列表
      getFirstCommentList() {
        this.$http
          .getPostCommentInfo({
            user_post_id: this.postDetailInfo.id,
            page: this.page,
            size: 50
          })
          .then((res) => {
            this.totalPage = res.data.last_page
            let firstCommentList = this.postDetailInfo.firstCommentList
            this.postDetailInfo.firstCommentList = [
              ...firstCommentList,
              ...res.data.data
            ]
          })
      },

      // 滚动加载
      scrollLoad: throttle(function () {
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight // document的滚动高度
        let nowScotop =
          document.documentElement.clientHeight || document.body.clientHeight // 可视区高度
        let wheight =
          document.documentElement.scrollTop || document.body.scrollTop // 已滚动高度
        if (
          nowScotop > scrollHeight - wheight - 5 &&
          this.totalPage > this.page
        ) {
          this.page++
          this.getFirstCommentList()
        }
        // 暂时没有更多
        if (this.page == this.totalPage) this.isShow = true
      })
    }
  }
</script>
<style>
  @media (min-width: 992px) and (max-width: 1365px) {
    .content-page #my_test_one {
      width: 50px;
    }
  }
</style>
<style lang="scss" scoped>
@media (max-width:600px) {
  .leftImg,.leftEvent,.ad-list{
    display: none;
  }
}
  .content-page {
    position: relative;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .no_more {
    position: relative;
    color: rgba(225, 225, 225, 0.5);
    text-align: center;

    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 27%;
      height: 1px;
      background: rgba(225, 225, 225, 0.5);
      top: 50%;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .toTop {
    position: fixed;
    bottom: 50px;
    right: 380px;
    width: 50px;
    height: 50px;
    background: #fff;
    font-size: 20px;
    color: #50b5ff;
    border-radius: 8px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    z-index: 9999;
  }

  .bottomItem {
    flex-wrap: wrap;
    font-size: 13px;
    color: #666666;
    line-height: 26px;

    li {
      white-space: nowrap;
      margin-right: 21px;
    }
  }

  /**
left
*/
  .upcomming_event {
    .card_title {
      font-family: "poppins" !important;
      color: #333;
      font-size: 20px;
    }

    .date_list {
      >li {
        display: flex;
        justify-content: space-between;
        align-items: inherit;

        >i {
          font-size: 20px;
          color: #50b5ff;
        }

        >.stories_data {
          width: calc(100% - 40px);
          box-sizing: border-box;

          h5 {
            font-size: 16px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          p {
            font-family: "montserra";
            color: #999;
          }
        }
      }
    }
  }

  .share_post_detail {
    .detail_back {
      color: #666;
      font-size: 16px;
    }
  }

  .attentionActive {
    color: #50b5ff;
  }

  .attention {
    color: #999;
  }

  .language {
    display: flex;
    justify-content: center;
    background: #ffffff;
    font-size: 14px;
    color: #333333;
    margin-top: 20px;

    .selection {
      width: 140px;
      border: 0 !important;
    }
  }

  .ad-list {
    >li {
      padding: 8px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 20px 0px rgba(43, 101, 143, 0.1);
      margin-bottom: 10px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>